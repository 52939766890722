import { useState } from "react";

export const useDialogLogic = () => {
    const [show, setShow] = useState(false);

    const toggleShow = () => {
        setShow((show) => !show);
    };

    return { show, toggleShow };
};
