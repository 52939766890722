import { createAsyncThunk } from "@reduxjs/toolkit";
import { GridAPI } from "../../common/DemoPreview/GridAPI";

export function addDataFunc(state, action) {
    if (!action.payload.item || !action.payload.module) {
        return state;
    }
    state[action.payload.module]?.data?.unshift({ ...action.payload.item, isNew: true });
    return state;
}

export function updateDataFunc(state, action) {
    let currData = state[action.payload.module].data;
    const { ids, toUpdate } = action.payload;
    ids.forEach((id) => {
        for (let i = 0; i < currData.length; i++) {
            let currentItem = currData[i];
            if (String(currentItem[action.payload.id]) === String(id)) {
                toUpdate.forEach((item) => {
                    currentItem[item.key] = item.value;
                });
                break;
            }
        }
    });
    state[action.payload.module].data = currData;
    return state;
}

export function replaceDataFunc(state, action) {
    if (!action.payload.idField || !action.payload.id) {
        return state;
    }
    const currData = state[action.payload.module].data;

    for (let i = 0; i < currData.length; i++) {
        if (action.payload.id === currData[i][action.payload.idField]) {
            currData[i] = action.payload.data;
            break;
        }
    }
    state[action.payload.module].data = currData;

    return state;
}

export function removeDataFunc(state, action) {
    const { ids } = action.payload;
    state[action.payload.module].data = state[action.payload.module].data
        .filter((c) => !ids.includes(c[action.payload.selectField]))
        .map((s) => {
            return { ...s, selected: false };
        });
    return state;
}

export function refreshDataFunc(state, action) {}

export const getDataThunk = createAsyncThunk("data/getData", async (data) => {
    const { module } = data;
    if (module === "tasks") {
        const { module = "", endDate = "All", apiID = "" } = data;
        const response = await GridAPI[module](endDate, apiID);
        return { ...response, module: module };
    } else if (module === "properties") {
        const response = await GridAPI[module]({ prefix: data?.gridPrefix });
        return { ...response, module: module };
    } else {
        const response = await GridAPI[module]();
        return { ...response, module: module };
    }
});
