function getLastDayOfMonth(year, month) {
    return new Date(year, month + 1, 0);
}

export function convertToISO(string) {
    const date = new Date();
    switch (string) {
        case "1 month": {
            const lastDay = getLastDayOfMonth(date.getFullYear(), date.getMonth());
            return lastDay;
        }
        case "2 months": {
            const lastDay = getLastDayOfMonth(date.getFullYear(), date.getMonth() + 1);
            return lastDay;
        }
        case "3 months": {
            const lastDay = getLastDayOfMonth(date.getFullYear(), date.getMonth() + 2);
            return lastDay;
        }
        case "4 months": {
            const lastDay = getLastDayOfMonth(date.getFullYear(), date.getMonth() + 3);
            return lastDay;
        }
        case "5 months": {
            const lastDay = getLastDayOfMonth(date.getFullYear(), date.getMonth() + 4);
            return lastDay;
        }
        case "6 months": {
            const lastDay = getLastDayOfMonth(date.getFullYear(), date.getMonth() + 5);
            return lastDay;
        }
        case "7 months": {
            const lastDay = getLastDayOfMonth(date.getFullYear(), date.getMonth() + 6);
            return lastDay;
        }
        case "8 months": {
            const lastDay = getLastDayOfMonth(date.getFullYear(), date.getMonth() + 7);
            return lastDay;
        }
        case "9 months": {
            const lastDay = getLastDayOfMonth(date.getFullYear(), date.getMonth() + 8);
            return lastDay;
        }
        case "10 months": {
            const lastDay = getLastDayOfMonth(date.getFullYear(), date.getMonth() + 9);
            return lastDay;
        }
        case "11 months": {
            const lastDay = getLastDayOfMonth(date.getFullYear(), date.getMonth() + 10);
            return lastDay;
        }
        case "1 year": {
            const lastDay = getLastDayOfMonth(date.getFullYear() + 1, date.getMonth());
            return lastDay;
        }

        default:
            return null;
    }
}
