import { process } from "@progress/kendo-data-query";
import Fuse from "fuse.js";

const moduleColumnsToProcess = {
    businesses: ["CompanyName", "type", "Email", "MainPhone", "FullAddress", "Website"],
    invoices: [
        "ContractDispId",
        "InvoiceId",
        "InvoiceType",
        "Property",
        "ProviderName",
        "ServiceCategory",
        "Services",
        "Client",
        "MgmtCompany",
        "OwnershipGroup",
    ],
    providers: ["ProviderName", "ServiceCategories", "Services", "FullAddress", "AgreementCount"],
    contacts: ["contactName", "email", "phone", "mobilePhone", "fullAddress", "fax"],
    properties: [
        "Property",
        "LegalName",
        "ClientName",
        "MgmtCompanyName",
        "OwnershipGroupName",
        "FullAddress",
        "Phone",
        "TaxId",
        "IsACHPayment",
        "DBA",
    ],
    serviceCategory: ["CategoryName"],
    services: ["ServiceName", "CategoryName"],
    documents: [
        "FileName",
        "FileType",
        "FileLength",
        "AttachmentType",
        "UserName",
        "AttachmentNote",
        "PropertyName",
        "ProviderName",
        "ServiceCategory",
        "Services",
    ],
    agreements: [
        "status_message",
        "ContractDispId",
        "Property",
        "ProviderName",
        "Client",
        "Services",
        "ServiceCategory",
        "ContractType",
        "QuickNote",
        "RolledOver",
        "RollingOver",
        "RolloverTerm",
        "MgmtCompany",
        "OwnershipGroup",
        "PropertyAddress",
        "memo",
        "PropertyAddressLine1",
        "PropertyAddressCity",
        "PropertyAddressState",
        "PropertyAddressZip",
        "LegalName",
        "IsPaymentRequired",
        "PaymentSentTo",
        "DBA",
    ],
    payments: [
        "ContractDispId",
        "Property",
        "ProviderName",
        "PaymentId",
        "TotalAmount",
        "Services",
        "ServiceCategory",
        "Client",
        "MgmtCompany",
        "OwnershipGroup",
    ],
    transactions: ["Property", "ProviderName", "PaymentId", "TotalAmount"],
    missingPayments: [
        "ContractDispId",
        "CombinedPropertyName",
        "ProviderName",
        "PaymentId",
        "TotalAmount",
        "Services",
        "ServiceCategory",
        "CombinedClientName",
        "CombinedMgmtCoName",
        "CombinedOwnerName",
    ],
    tasks: ["category", "module", "status", "createdBy.user"],
};

const fuseOptions = {
    includeScore: true,
    threshold: 0.3,
    includeMatches: true,
};

export function gridDataFormat(module, data) {
    console.time(`[${module}] Grid Data Format Execution Time`);
    const fuseSet = new Set();
    const state = {};
    moduleColumnsToProcess[module].forEach((c) => {
        let groupResult = process(data, {
            group: [
                {
                    field: c,
                },
            ],
        });
        let uniqueValues = new Set();
        groupResult.data
            .filter((f) => f.value !== null && f.value !== "" && f.value !== undefined)
            .forEach((d) => {
                if (String(d.value).includes(";")) {
                    d.value.split(";").forEach((a) => {
                        let trimValue = a.trim();
                        uniqueValues.add(trimValue);
                        fuseSet.add(trimValue);
                    });
                } else {
                    uniqueValues.add(d.value);
                    fuseSet.add(String(d.value));
                }
            });
        state[c] = [...uniqueValues];
    });

    const fuse = new Fuse(Array.from(fuseSet), fuseOptions);

    console.timeEnd(`[${module}] Grid Data Format Execution Time`);

    return { rawData: data, filterState: state, fuse: fuse };
}
