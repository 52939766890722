export const normalizeInput = (currentValue) => {
    if (currentValue === "" || currentValue === null || currentValue === undefined) {
        return "";
    } else if (currentValue.startsWith("(")) {
        return currentValue;
    }
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)} ${currentValue.slice(
        10
    )}`.trim();
};

export const normalizeInputWithCC = (currentValue) => {
    if (currentValue === "" || currentValue === null || currentValue === undefined) {
        return "";
    } else if (currentValue.startsWith("(")) {
        return currentValue;
    }
    return `+1 (${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
};

export function checkFieldsForPhone(object) {
    Object.keys(object).forEach((field) => {
        if (
            field.includes("Phone") ||
            field.includes("Mobile") ||
            field.includes("phone") ||
            field.includes("mobile")
        ) {
            object[field] = normalizeInput(String(object[field]));
        }
    });

    return object;
}
