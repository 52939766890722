import { createSlice } from "@reduxjs/toolkit";

import { getDataThunk, updateDataFunc, removeDataFunc, addDataFunc, replaceDataFunc } from "./dataFunctions";

export const dataSlice = createSlice({
    name: "data",
    initialState: {
        dashboards: { data: [], filterState: {} },
        tasks: { data: [], filterState: {} },
        businesses: { data: [], filterState: {} },
        contacts: { data: [], filterState: {} },
        properties: { data: [], filterState: {} },
        services: { data: [], filterState: {} },
        agreements: { data: [], filterState: {} },
        payments: { data: [], filterState: {} },
        reports: { data: [], filterState: {} },
        admin: { data: [], filterState: {} },
        providers: { data: [], filterState: {} },
        missingPayments: { data: [], filterState: {} },
        transactions: { data: [], filterState: {} },
        serviceCategory: { data: [], filterState: {} },
    },
    reducers: {
        addData: (state, action) => {
            addDataFunc(state, action);
        },
        updateData: (state, action) => {
            updateDataFunc(state, action);
        },
        replaceData: (state, action) => {
            replaceDataFunc(state, action);
        },
        removeData: (state, action) => {
            removeDataFunc(state, action);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getDataThunk.fulfilled, (state, action) => {
            if (action?.meta?.arg?.apiID) {
                return state;
            }
            state[action.payload.module].data = action.payload.rawData;
            state[action.payload.module].filterState = action.payload.filterState;
        });
        builder.addCase(getDataThunk.rejected, (state, action) => {
            console.log("GET DATA THUNK REJECTED");
            return state;
        });
    },
});

export const { updateData, addData, replaceData, removeData } = dataSlice.actions;

export default dataSlice.reducer;
