import React, { useEffect, useState, useRef, useCallback } from "react";
import Pusher from "pusher-js";
import { useSessionContext } from "supertokens-auth-react/recipe/session";
import { useDispatch } from "react-redux";

import { updateInfo } from "../reducers/info/infoSlice";
import { addData, removeData, replaceData } from "../reducers/data/dataSlice";
import { updateErrors } from "../reducers/errors/errorsSlice";

const PusherContext = React.createContext(null);

const MAX_RETRIES = 10; // Maximum number of retries
const RETRY_DELAY = 15000; // Delay between retries in milliseconds

const PusherProvider = ({ children }) => {
    const dispatch = useDispatch();
    const { userId } = useSessionContext();

    const [pusher, setPusher] = useState(null);

    const signinAttemptsRef = useRef(0);
    const channelsRef = useRef({});

    const subscribeToChannel = useCallback(
        (channelName) => {
            if (!pusher) {
                return;
            }

            const channels = channelsRef.current;

            if (channels[channelName]) {
                return channels[channelName];
            }

            console.log("[PUSHER] Attempting to subscribe to", channelName);

            const channel = pusher?.subscribe(channelName);

            channel.bind("pusher:subscription_succeeded", () => {
                console.log("[PUSHER] Successfully connected to", channelName);
            });

            channelsRef.current = { ...channels, [channelName]: channel };

            return channel;
        },
        [pusher]
    );

    const subscribeToPresenceChannel = useCallback(
        (channelName) => {
            const channels = channelsRef.current;

            if (channels[channelName]) {
                return channels[channelName];
            }

            const channel = pusher?.subscribe(channelName);

            channelsRef.current = { ...channels, [channelName]: channel };

            return channel;
        },
        [pusher]
    );

    const removeChannel = useCallback((channelName) => {
        const channels = channelsRef.current;

        if (channels.hasOwnProperty(channelName)) {
            channels[channelName].unbind_all();
            channels[channelName].unsubscribe();

            const { [channelName]: _, ...rest } = Object.assign({}, channels);

            channelsRef.current = rest;
        }
    }, []);

    useEffect(() => {
        try {
            if (!userId) {
                return;
            }
            console.log("[PUSHER] ESTABLISHING CONNECTION");

            const pusherInstance = new Pusher(import.meta.env.REACT_APP_PUSHER_KEY, {
                cluster: import.meta.env.REACT_APP_PUSHER_CLUSTER,
                forceTLS: true,
                channelAuthorization: {
                    endpoint: `${import.meta.env.REACT_APP_PUSHER_AUTH}pusher/auth`,
                },
                userAuthentication: {
                    endpoint: `${import.meta.env.REACT_APP_PUSHER_AUTH}pusher/user-auth`,
                },
            });

            pusherInstance.connection.bind("error", function (err) {
                if (err?.error?.data?.code === 4004) {
                    console.error("Over limit!");
                }

                // handle other error codes or network errors
                if (err?.error?.data?.code === 2001) {
                    // Unable to reach server
                    console.error("Connection Refused - Unable to reach server");
                }

                // generic error handling
                console.error("Pusher connection error:", err);
            });

            setPusher(pusherInstance);
        } catch (error) {
            console.error("[PUSHER] ERROR WHILE ESTABLISHING CONNECTION: ", error);
            dispatch(updateErrors({ key: "pusher", value: true }));
        }
    }, [dispatch, userId]);

    useEffect(() => {
        const signin = (pusherInstance) => {
            if (!pusherInstance) {
                console.error("[PUSHER] NO INSTANCE");
                dispatch(updateErrors({ key: "pusher", value: true }));

                return;
            }

            if (signinAttemptsRef.current >= MAX_RETRIES) {
                console.error("[PUSHER] MAX SIGNIN ATTEMPTS REACHED");
                return;
            }

            try {
                console.log("[PUSHER] ATTEMPTING SIGN IN");
                pusherInstance.signin();
                // dispatch(updateErrors({ key: "pusher", value: false }));
            } catch (error) {
                console.error("[PUSHER] ERROR WHILE SIGNING IN: ", error);
                setTimeout(() => signin(pusherInstance), RETRY_DELAY);
                signinAttemptsRef.current++;
                dispatch(updateErrors({ key: "pusher", value: true }));
            }
        };

        if (pusher && userId) {
            pusher.bind("pusher:signin_success", () => {
                console.log("[PUSHER] SUCCESSFUL SIGN IN");
                signinAttemptsRef.current = 0; // Reset attempts on successful signin

                dispatch(updateErrors({ key: "pusher", value: false }));
            });

            pusher.bind("pusher:error", () => {
                console.log("[PUSHER] ERROR WITH USER AUTHENTICATION");
                dispatch(updateErrors({ key: "pusher", value: true }));
            });

            pusher.bind("pusher:subscription_error", () => {
                console.log("[PUSHER] ERROR WITH USER AUTHENTICATION");
                dispatch(updateErrors({ key: "pusher", value: true }));
            });

            signin(pusher);
        }

        return () => {
            if (pusher) {
                pusher.unbind_all(); // Unbind all events
                pusher.disconnect(); // Disconnect from Pusher
            }
        };
    }, [pusher, dispatch, userId]);

    useEffect(() => {
        if (!pusher || !userId) {
            return;
        }

        pusher.connection.bind("failed", function () {
            console.log("[PUSHER] FAILED");
        });

        pusher.connection.bind("connected", function () {
            console.log("[PUSHER] CONNECTED", pusher.connection.socket_id);
            const socketId = pusher.connection.socket_id;
            dispatch(updateInfo({ key: "socketId", value: socketId }));
        });

        pusher.connection.bind("pusher:error", function (error) {
            console.log("[PUSHER] ERROR", error);
        });

        const channel = pusher.subscribe(`private-${userId}`);

        channel.bind("replace", (pusherData) => {
            try {
                const { idField = "", ids = [], data = {}, module = "" } = pusherData;
                if (Object.keys(data).length === 0 || ids.length === 0) {
                    return;
                }
                if (!idField || !module) {
                    return;
                }

                data.isNew = true;

                dispatch(
                    replaceData({
                        idField: idField,
                        id: ids[0],
                        module: module,
                        data: data,
                    })
                );
            } catch (error) {
                console.log(error);
            }
        });

        channel.bind("created", (pusherData) => {
            try {
                const { data = [], module = "" } = pusherData;
                if (data.length === 0 || !module) {
                    return;
                }

                data.isNew = true;

                dispatch(
                    addData({
                        module: module,
                        item: data,
                    })
                );
            } catch (error) {
                console.log(error);
            }
        });

        channel.bind("remove", (pusherData) => {
            try {
                const { module = "", idField = "", ids = [] } = pusherData;
                if (ids.length === 0 || !module || !idField) {
                    return;
                }
                dispatch(
                    removeData({
                        module: module,
                        ids: ids,
                        selectField: idField,
                    })
                );
            } catch (error) {
                console.log(error);
            }
        });
        return () => {
            console.log("[PUSHER] DISCONNECT");
            pusher.unbind_all();
            pusher.unsubscribe(`private-${userId}`);
            pusher.disconnect();
        };
    }, [dispatch, userId, pusher]);

    return (
        <PusherContext.Provider
            value={{
                pusher,
                channels: channelsRef.current,
                subscribeToChannel,
                subscribeToPresenceChannel,
                removeChannel,
            }}
        >
            {children}
        </PusherContext.Provider>
    );
};

export { PusherProvider, PusherContext };
